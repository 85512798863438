<template>
  <div>
    <b-modal :active.sync="isModalImportActive" has-modal-card>
      <import-form
        :model="model"
        @imported="() => (loadAsyncData(), afterImport())"
        @close="isModalImportActive = false"
      >
        <template #title>
          <slot name="import-label"></slot>
        </template>
        <template #hint>
          <slot name="import-hint"></slot>
        </template>
      </import-form>
    </b-modal>
    <div class="header">
      <div class="header__search">
        <b-input
          icon="magnify"
          v-model="search"
          v-if="!disableSearch"
          :placeholder="placeholder"
        ></b-input>
        <slot name="global-filter"></slot>
      </div>
      <div class="header__actions">
        <slot name="header-actions"></slot>
        <b-button
          type="is-warning"
          v-if="!disableImport"
          @click="isModalImportActive = true"
        >
          <slot name="import-label">Importer</slot>
        </b-button>
        <b-button
          type="is-warning"
          v-if="!disableCreate"
          tag="router-link"
          :to="`/${model}/create`"
        >
          <slot name="create-label">Ajouter</slot>
        </b-button>
        <b-button
          type="is-warning"
          v-if="!disableExport"
          tag="a"
          :href="exportUrl"
          >Exporter les données</b-button
        >
      </div>
    </div>
    <slot />
    <b-table
      ref="datatable"
      striped
      paginated
      backend-sorting
      backend-pagination
      backend-filtering
      :per-page="perPage"
      :current-page.sync="page"
      :total="total"
      :default-sort="[sortField, sortOrder]"
      :data="data"
      :loading="loading"
      @page-change="onPageChange"
      @sort="onSort"
      @filters-change="onFilter"
    >
      <template v-for="column in columns.filter((c) => !c.hidden)">
        <b-table-column :key="column.id" v-bind="column">
          <template v-slot:header="{ column }">
            <span :id="`${column.field}-th`" class="th-label">
              {{ column.label }}
              <b-icon
                v-if="column.label"
                icon="chevron-down"
                size="is-small"
              ></b-icon>

              <template v-if="sortField === column.field">
                <b-icon
                  v-if="sortOrder === 'desc'"
                  icon="arrow-down"
                  size="is-small"
                ></b-icon>
                <b-icon
                  v-if="sortOrder === 'asc'"
                  icon="arrow-up"
                  size="is-small"
                ></b-icon>
                <a href="javascript:void(0)" @click.stop="onSort(null, null)">
                  <b-icon icon="close" size="is-small" type="is-white"></b-icon>
                </a>
              </template>
            </span>
          </template>
          <template
            v-if="column.searchable"
            slot="searchable"
            slot-scope="props"
          >
            <slot :name="`filter-${column.field}`" v-bind="props">
              <div class="table__filter">
                <base-datepicker
                  v-if="column.type === 'date'"
                  placeholder="Sélectionner"
                  v-model="props.filters[props.column.field]"
                  range
                  size="is-small"
                >
                  <b-button
                    type="is-primary"
                    @click="props.filters[props.column.field] = undefined"
                  >
                    Réinitialiser
                  </b-button>
                </base-datepicker>
                <b-select
                  v-else-if="column.type === 'select'"
                  size="is-small"
                  v-model="props.filters[props.column.field]"
                  :placeholder="column.label"
                >
                  <option value=""></option>
                  <option
                    v-for="(name, value) in column.options"
                    :value="value"
                    :key="value"
                  >
                    {{ name }}
                  </option>
                </b-select>
                <b-input
                  v-else
                  v-model="props.filters[props.column.field]"
                  size="is-small"
                  :placeholder="column.label"
                  :disabled="props.filters[props.column.field] === false"
                ></b-input>

                <b-checkbox
                  v-if="column.nullable"
                  v-model="props.filters[props.column.field]"
                  size="is-small"
                  type="is-warning"
                  :true-value="false"
                  false-value=""
                  title="Vide"
                  class="ml-2"
                >
                </b-checkbox>
              </div>
            </slot>
          </template>
          <template v-slot="props">
            <slot :name="`field-${column.field}`" v-bind="props">
              <div :class="`field-${column.field}`">
                {{ props.row[column.field] || '-' }}
              </div>
            </slot>
          </template>
        </b-table-column>
      </template>
      <b-table-column v-slot="props" v-if="!disableActions">
        <div class="row-actions">
          <slot name="row-actions" v-bind="props"></slot>
        </div>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p v-if="loading">Chargement...</p>
            <p v-else>Aucun résultat</p>
          </div>
        </section>
      </template>
      <div slot="bottom-left">
        <div class="bottom-left-section">
          <b-select v-model="perPage">
            <option value="10">10 / page</option>
            <option value="20">20 / page</option>
          </b-select>
          <span class="total-records" v-if="total">{{ total }} élément(s)</span>
        </div>
      </div>
    </b-table>
  </div>
</template>

<script>
import ImportForm from '@/components/ImportForm.vue'
import qs from 'qs'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

export default {
  components: {
    ImportForm,
  },
  props: {
    model: String,
    defaultSortField: String,
    defaultSortOrder: String,
    columns: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
    disableCreate: Boolean,
    disableImport: Boolean,
    disableExport: Boolean,
    disableSearch: Boolean,
    disableActions: Boolean,
    placeholder: {
      type: String,
      default: 'Rechercher un nom, un email,...',
    },
    afterImport: {
      type: Function,
      default: () => {},
    },
  },
  provide() {
    return {
      model: this.model,
    }
  },
  data: () => ({
    search: null,
    isModalImportActive: false,
    data: [],
    loading: false,
    perPage: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    total: 0,
    currentFilter: {},
    currentQuery: {},
    disableLoad: false,
  }),
  computed: {
    exportUrl() {
      // eslint-disable-next-line no-unused-vars
      let { page, perPage, ...query } = this.backendQuery

      let url = `${this.$apiURL}/api/${this.model}`

      if (isEmpty(query)) {
        return url
      }

      return `${url}?${qs.stringify({ ...query, export: true })}`
    },
    backendQuery() {
      let query = { ...this.currentQuery }

      /**
       * On rajoute le filtre interne du datatable
       */
      query.filter = { ...query.filter, ...this.filter }

      /**
       * Tri par défault si aucun tri renseigné
       */
      if (!query.sort) {
        let sortField = this.defaultSortField
        let sortOrder = this.defaultSortOrder

        if (sortField) {
          query.sort = sortOrder === 'desc' ? `-${sortField}` : sortField
        }
      }

      return query
    },
  },
  watch: {
    perPage() {
      this.loadAsyncData()
    },
    filter() {
      this.loadAsyncData()
    },
    search() {
      this.debounceInput()
    },
    async currentQuery(newVal) {
      /**
       * Mettre à jour la QS URL
       */
      let query = { ...newVal }

      if (!isEmpty(newVal.filter)) {
        query.filter = JSON.stringify(newVal.filter)
      } else if (newVal.filter === undefined) {
        query.filter = JSON.stringify(newVal.filter)
      }

      this.disableLoad = true

      await this.$router
        .push({ query: { ...this.$route.query, ...query } })
        .catch(() => {})

      this.disableLoad = false
    },
  },
  mounted() {
    this.initFromQuery()
    this.loadAsyncData()
  },
  methods: {
    debounceInput: debounce(function () {
      this.loadAsyncData()
    }, 200),
    onPageChange(page) {
      this.page = page
      this.loadAsyncData()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadAsyncData()

      if (field === null) {
        this.$refs.datatable.currentSortColumn = null
      }
    },
    onFilter(filter) {
      let currentFilter = {}
      Object.keys(filter).forEach((k) => {
        if (filter[k] !== undefined && filter[k] !== '') {
          currentFilter[k] = filter[k]
        }
      })
      this.currentFilter = currentFilter
      this.debounceInput()
    },
    initFromQuery() {
      let { page, perPage, filter, sort } = this.$route.query

      if (perPage) {
        this.perPage = parseInt(perPage, 10)
      }

      if (page) {
        this.page = parseInt(page, 10)
      }

      if (sort) {
        if (sort.charAt(0) === '-') {
          this.sortOrder = 'desc'
          this.sortField = sort.slice(1)
        } else {
          this.sortField = sort
        }
      }

      if (filter) {
        filter = JSON.parse(filter)

        if (!isEmpty(filter)) {
          let { q, ...f } = filter

          if (q) {
            this.search = q
          }

          if (!isEmpty(f)) {
            this.currentFilter = f

            // Hack pour pris en compte des filtres
            this.$refs.datatable.filters = filter
          }
        }
      }
    },
    async loadAsyncData() {
      if (!this.model || this.disableLoad) {
        return
      }
      this.loading = true

      let query = {
        perPage: this.perPage,
        page: this.page,
      }

      let sortField = this.sortField
      let sortOrder = this.sortOrder

      if (sortField) {
        query.sort = sortOrder === 'desc' ? `-${sortField}` : sortField
      }

      let filter = { ...this.currentFilter }

      if (!isEmpty(this.search)) {
        filter.q = this.search
      }

      if (!isEmpty(filter)) {
        query.filter = filter
      }

      /**
       * Mettre à jour la query de recherche
       */
      this.currentQuery = query

      /**
       * Requête backend
       */
      let { data } = await this.$axios.get(
        `/api/${this.model}?${qs.stringify(this.backendQuery)}`
      )

      this.data = data.data
      this.total = data.meta.total

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;

  .header__search {
    margin-bottom: 1.5rem;
  }

  .header__actions {
    display: flex;
    flex-direction: column;
  }

  .button {
    margin-bottom: 1.5rem;
  }

  @include tablet {
    flex-direction: row;
    justify-content: space-between;

    .header__search {
      display: flex;

      .control {
        width: 400px;
        margin-right: 1rem;
      }
    }

    .header__actions {
      flex-direction: row;
    }

    .button {
      margin-left: 1rem;
    }
  }
}

.table__filter {
  display: flex;
  align-items: center;
}

/deep/ .b-table .table {
  border-color: #dbdbdb;
  th {
    background: $primary;
    color: $white;
    border: 0;
    font-weight: normal;

    .th-label {
      white-space: nowrap;
    }

    .is-small {
      border-radius: 6px;
    }
  }

  td,
  th {
    vertical-align: middle;
  }
}

/deep/ .row-actions {
  white-space: nowrap;
  text-align: right;

  .button {
    background-color: transparent;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0 0.75rem;

    & .icon:first-child:last-child {
      font-size: 1.5rem;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.bottom-left-section {
  display: flex;
  align-items: center;

  .total-records {
    margin-left: 1rem;
  }
}

/deep/ .table-wrapper {
  min-height: 80vh !important;
}

/deep/ .table-wrapper {
  @include tablet {
    overflow-y: hidden;
    scrollbar-width: thin;
  }

  @include fullhd {
    overflow-y: inherit;
  }
}
</style>
