<template>
  <div :style="colors" class="container is-fluid">
    <h1>{{ $route.meta.title }}</h1>

    <base-data-table
      model="archives"
      ref="datatable"
      :columns="columns"
      :filter="filter"
      disable-import
      placeholder="Rechercher un nom, un prénom, un IDGRC..."
      disable-create
      disable-search
    >
      <span slot="create-label">
        <span>Envoyer une réclamation</span>
      </span>

      <template v-slot:field-action="props">
        <base-show-button :id="props.row.id"></base-show-button>
      </template>

      <template v-slot:field-regional_service_slug="props">
        <span class="regional_service_slug">
          {{ services[props.row.regional_service_slug] }}
        </span>
      </template>

      <template v-slot:field-user_role="props">
        <span class="user_role">
          {{ roles[props.row.user_role] }}
        </span>
      </template>

      <template v-slot:filter-user_id="props">
        <base-autocomplete
          size="is-small"
          v-model="props.filters[props.column.field]"
          model="users"
          placeholder="Utilisateur"
          :text="(model) => model.name"
        >
        </base-autocomplete>
      </template>

      <template v-slot:field-user_id="props">
        <span v-if="props.row.user" class="tag">
          {{ props.row.user.name }}
        </span>
      </template>

      <template v-slot:field-status="props">
        <span class="tag" :class="props.row.status">
          {{ status[props.row.status] }}
        </span>
      </template>

      <template v-slot:field-criticality="props">
        <span :class="props.row.criticality">
          {{ criticality[props.row.criticality] }}
        </span>
      </template>

      <template v-slot:field-regional-service-slug="props">
        <span class="regional_service_slug">
          {{ services[props.row.regional_service_slug] }}
        </span>
      </template>

      <template v-slot:field-status_new_at="props">
        {{ $formatDate(props.row.status_new_at, 'dd/MM/yyyy') }}
      </template>

      <template v-slot:field-status_dealing_at="props">
        <div class="field-status_dealing_at">
          {{ $formatDate(props.row.status_dealing_at, 'dd/MM/yyyy') }}
        </div>
      </template>

      <template v-slot:row-actions="props">
        <base-show-button
          v-if="!user.is_admin"
          :id="props.row.id"
        ></base-show-button>
      </template>
    </base-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      roles: (state) => state.enums.roles,
      services: (state) => state.enums.services,
      criticality: (state) => state.enums.complaint.criticality,
      status: (state) => state.enums.complaint.status,
      type: (state) => state.enums.complaint.type,
      complaintsImportDate: (state) => state.complaintsImportDate,
    }),
    columns() {
      return [
        {
          field: 'action',
          label: '',
          hidden: !this.user.is_admin,
        },
        {
          field: 'status_new_at',
          label: 'Création',
          type: 'date',
          sortable: true,
          searchable: true,
        },
        {
          field: 'status',
          label: 'Statut',
          searchable: true,
          type: 'select',
          sortable: true,
          options: this.status,
        },
        {
          field: 'criticality',
          label: 'Criti.',
          searchable: true,
          type: 'select',
          sortable: true,
          options: this.criticality,
        },
        {
          field: 'idgrc',
          label: 'IDGRC',
          searchable: true,
          sortable: true,
        },
        {
          field: 'id_trace',
          label: 'ID trace',
          searchable: true,
          sortable: true,
        },
        {
          field: 'lastname',
          label: 'Nom',
          searchable: true,
          sortable: true,
        },
        {
          field: 'firstname',
          label: 'Prénom',
          searchable: true,
          sortable: true,
        },
        {
          field: 'postcode_city',
          label: 'CP Ville',
          searchable: true,
          sortable: true,
        },
        {
          field: 'regional_service_slug',
          label: 'Caisse',
          searchable: true,
          type: 'select',
          sortable: true,
          options: this.services,
          hidden: !this.user.is_admin,
        },
        {
          field: 'user_id',
          label: 'Utilisateur',
          searchable: true,
          sortable: false,
          hidden:
            !this.user.is_admin &&
            !this.user.is_penpal &&
            !this.user.is_manager,
        },
        {
          field: 'status_dealing_at',
          label: 'Prise charge',
          type: 'date',
          searchable: true,
          sortable: true,
        },
      ]
    },
    colors() {
      return {
        '--criticality-low': this.$criticalityColors.LOW,
        '--criticality-medium': this.$criticalityColors.MEDIUM,
        '--criticality-high': this.$criticalityColors.HIGH,
        '--status-new': this.$statusColors.NEW,
        '--status-dealing': this.$statusColors.DEALING,
        '--status-processed': this.$statusColors.PROCESSED,
        '--status-dropped': this.$statusColors.DROPPED,
      }
    },
    filter() {
      return { ...this.$route.meta.filter, inactive: this.inactive }
    },
  },
  mounted() {
    this.getLastUpdate()
  },
  methods: {
    loadAsyncData() {
      this.$refs.datatable.loadAsyncData()
    },
    async getLastUpdate() {
      this.$store.dispatch('loadComplaintsImportDate')
    },
  },
}
</script>

<style scoped>
.NEW {
  background-color: var(--status-new);
  color: white;
}

.DEALING {
  background-color: var(--status-dealing);
}

.PROCESSED {
  background-color: var(--status-processed);
  color: white;
}

.DROPPED {
  background-color: var(--status-dropped);
  color: white;
}

.LOW {
  font-weight: 600;
}

.MEDIUM {
  font-weight: 600;
}

.HIGH {
  font-weight: 600;
  color: var(--criticality-high);
}
</style>
