<template>
  <div class="login">
    <div class="panel">
      <div class="logo">
        <img
          alt="Groupama Loire bretagne"
          src="../assets/logo.webp"
          width="250"
        />
      </div>
      <h4 class="header-text-message">
        Veuillez vous identifier pour accéder à votre espace :
      </h4>
      <form class="login-form" @submit.prevent="onLogin">
        <b-field label="Email" label-for="email">
          <b-input id="email" type="email" required v-model="form.email">
          </b-input>
        </b-field>

        <b-field label="Mot de passe" label-for="password">
          <b-input
            id="password"
            type="password"
            required
            v-model="form.password"
          >
          </b-input>
        </b-field>

        <b-field>
          <div class="remember">
            <b-checkbox v-model="form.remember">Se souvenir de moi</b-checkbox>
            <a
              href="javascript:void(0)"
              @click="isPasswordResetModalActive = true"
              >Mot de passe oublié ?</a
            >
          </div>
        </b-field>
        <b-button class="block" native-type="submit" :loading="pending">
          Se connecter
        </b-button>
      </form>

      <b-message
        auto-close
        :active.sync="isMessageActive"
        type="is-warning"
        :duration="5000"
      >
        {{ errorMessage }}
      </b-message>

      <span v-if="version !== 'null'" class="app-version">
        {{ version }}
      </span>
    </div>

    <b-modal :active.sync="isPasswordResetModalActive" has-modal-card>
      <reset-form :email="form.email"></reset-form>
    </b-modal>
  </div>
</template>

<script>
import ResetForm from '@/components/ResetForm.vue'

export default {
  components: {
    ResetForm,
  },
  data: () => ({
    form: {
      email: null,
      password: null,
      remember: false,
    },
    isPasswordResetModalActive: false,
    errorMessage: null,
    isMessageActive: false,
    pending: false,
    version: process.env.VUE_APP_VERSION,
  }),
  methods: {
    async onLogin() {
      this.pending = true

      try {
        await this.$axios.post('/auth/login', this.form)
        this.$router.push({ name: 'Home' })
      } catch (e) {
        if (!e.response) {
          this.errorMessage = e.message
        } else if (e.response.data.errors) {
          this.errorMessage = e.response.data.errors.email[0]
        }
        this.isMessageActive = true
      }
      this.pending = false
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  text-align: center;
}

.login {
  background: url(/splash.webp) no-repeat;
  background-size: cover;
  background-position: 350px;
  height: 100%;
}

.login-form {
  margin-bottom: 1.5rem;
}

.panel {
  overflow: auto;
  background: $white;
  scrollbar-width: thin;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 0;

  @include tablet {
    width: 550px;
    padding: 4rem;
  }
}

.header-text-message {
  margin: 5rem 0;
}

.remember {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: $orange;
    text-decoration: underline;
    font-style: italic;
  }
}

.app-version {
  color: $grey;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

/deep/ .label {
  font-weight: normal;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

/deep/ .field:not(:last-child) {
  margin-bottom: 2rem;
}

/deep/ .input {
  height: 3.5rem !important;
}
</style>
