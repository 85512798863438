import { render, staticRenderFns } from "./Legal.vue?vue&type=template&id=84b72d04&scoped=true&"
var script = {}
import style0 from "./Legal.vue?vue&type=style&index=0&id=84b72d04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b72d04",
  null
  
)

export default component.exports