<template>
  <div class="card">
    <div class="card-content">
      <div>
        <p class="text-red text-center font-bold">
          Cet outil ne concerne que les réclamations GBH pour les contrats GBH
          souscrits depuis mars 2021
        </p>
        <p>
          <b
            >En cas de demande d'information ou de SAV sur sa GBH, votre client
            doit appeler directement le service client GBH</b
          >, depuis sa maison pour qu'il puisse être guidé. 90% des demandes
          clients sont traitées en direct et le temps d'attente est de 1 min
          maximum. Le service client est ouvert du lundi au samedi de 8h à 20h
          et joignable depuis l'appli ou en appelant le
          <a href="tel:+339 69 36 88 39">09 69 36 88 39</a>.
        </p>
        <p class="text-red text-center font-bold">
          Les demandes saisies dans cet outil, qui ne sont pas des réclamations,
          ne pourront pas être traitées.
        </p>
        <p>
          <b>Une réclamation, c'est un mécontentement client</b> en lien avec
          une installation mal faite ou inachevée, un engagement non tenu...
        </p>
        <p>Quelques exemples :</p>
        <ul>
          <li>
            <b>Réclamations</b> : un RDV non honoré, un RDV plusieurs fois
            reporté, une installation non aboutie, un problème avec un
            technicien, une demande de SAV non réglée...
          </li>
          <li>
            <b>Demande</b> : renvoi d’un document, réglages (intensité de
            l’alarme, temporisation, appairage...), erreur/question sur une
            facture, dysfonctionnement d'un équipement, question sur
            l’équipement ou l’appli, demande d’ajout d’un matériel, demande de
            rétractation ou d’annulation...
          </li>
        </ul>
      </div>
      <!-- <div class="customer-service">
        <div>
          <p>Service client Groupama Box Habitat</p>
          <img src="@/assets/service-client.jpg" alt="" />
        </div>
      </div> -->
      <p v-if="updateMessage">
        <strong>Mise à jour des collaborateurs</strong> : si de nouveaux
        collaborateurs arrivent dans votre équipe, pensez à les ajouter
        directement dans l'outil et à les accompagner lors de leur 1ère
        connexion. Leur identifiant sera envoyé sur leur adresse mail. Si des
        collaborateurs quittent votre équipe, pensez à les supprimer.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    updateMessage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
p,
ul {
  margin: 1rem 0;
}
ul {
  list-style: disc;
  list-style-position: inside;
}
.highlight {
  color: $primary;
  font-weight: 600;
  text-align: center;
}
.text-center {
  text-align: center;
}
.text-red {
  color: red;
}
.font-bold {
  font-weight: 700;
}
.customer-service {
  display: flex;
  justify-content: center;
  p {
    text-align: center;
    color: #cb521b;
    font-weight: 700;
  }
}
</style>
