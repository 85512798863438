<template>
  <header>
    <router-link :to="{ name: 'Home' }">
      <img
        alt="Groupama Loire bretagne"
        src="@/assets/logo.webp"
        width="250"
        class="logo"
      />
      <img
        alt="Groupama Loire bretagne"
        src="@/assets/logo-min.jpg"
        width="70"
        height="70"
        class="logo-min"
      />
    </router-link>
    <!-- A rendre dynamique, affiche le logo du partenaire -->
    <img
      v-if="user && user.logo_media"
      :alt="user.name"
      :src="user.logo_media.url"
      width="400"
      class="logo-partner"
    />

    <!-- Account -->
    <b-dropdown position="is-bottom-left" append-to-body v-if="user">
      <a
        class="account"
        :class="{ 'is-impersonate': user.is_impersonating }"
        slot="trigger"
        role="button"
      >
        <span class="user">{{ user.name }}</span>
        <b-icon icon="account-circle-outline" size="is-large"></b-icon>
      </a>

      <b-dropdown-item custom aria-role="menuitem" v-if="user.is_impersonating">
        Connecté en tant que <b>{{ user.name }}</b>
      </b-dropdown-item>
      <b-dropdown-item aria-role="menuitem" @click="onCookiesManagement">
        <div>
          <b-icon icon="cookie-cog"></b-icon>
          Gérer mes cookies
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="menuitem"
        @click="stopImpersonate"
        v-if="user.is_impersonating"
      >
        <div>
          <b-icon icon="lock-open-outline"></b-icon>
          Arrêter l'usurpation
        </div>
      </b-dropdown-item>
      <hr class="dropdown-divider" v-if="user.is_impersonating" />
      <b-dropdown-item
        has-link
        aria-role="menuitem"
        v-if="!user.is_impersonating"
      >
        <router-link to="/password/change">
          <b-icon icon="form-textbox-password"></b-icon>
          Changer mon mot de passe
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="menuitem" @click="onLogout">
        <div>
          <b-icon icon="logout"></b-icon>
          Déconnexion
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    onCookiesManagement() {
      if (window.Optanon) {
        window.Optanon.ToggleInfoDisplay()
      }
    },
    async onLogout() {
      await this.$axios.post('/auth/logout')
      this.$router.push({ name: 'Login' })
    },
    async stopImpersonate() {
      await this.$axios.post('/api/users/stopImpersonate')

      location.href = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: $white;

  .logo {
    display: none;
    max-width: 350px;
  }

  .logo-partner {
    max-width: 150px;
  }

  @include desktop {
    padding: 2rem;
    .logo {
      display: inline;
    }
    .logo-min {
      display: none;
    }
    .logo-partner {
      max-width: inherit;
      width: auto;
      height: auto;
      max-height: 80px;
    }
  }
}

.account {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  color: inherit;
  padding: 0.5rem;

  > .user {
    display: none;
  }

  .icon {
    opacity: 0.3;
  }

  &.is-impersonate {
    background-color: $yellow;
  }

  @include desktop {
    > .user {
      display: inline;
    }
    .icon {
      margin-left: 1rem;
    }
  }
}

.dropdown-item,
.dropdown .dropdown-menu .has-link {
  > div,
  > a {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.5rem;
    }
  }
}
</style>
