<template>
  <div class="card" :class="{ horizontal: horizontal }">
    <div class="card-content">
      <div class="columns is-multiline">
        <div
          class="column"
          :class="{ 'is-full': !horizontal, 'is-5': horizontal }"
        >
          <h4>Nombre de réclamations</h4>
          <lead-chart
            class="chart__lead"
            :data="leadData"
            :total="leadTotal"
          ></lead-chart>
          <div class="days-avg columns">
            <div class="column">
              <div class="days-avg__number text-primary">
                {{ chartData.days_before_open }}
              </div>
              <div class="days-avg__text">
                Nbre de <span v-if="chartData.days_before_open > 1">jours</span
                ><span v-else>jour</span> pour prendre un charge une réclamation
              </div>
            </div>
            <div class="column">
              <div class="days-avg__number text-orange">
                {{ chartData.days_before_process }}
              </div>
              <div class="days-avg__text">
                Nbre de
                <span v-if="chartData.days_before_process > 1">jours</span
                ><span v-else>jour</span> pour traiter une réclamation
              </div>
            </div>
          </div>
        </div>
        <div class="column" v-if="showRate || showDays">
          <div class="additional-data">
            <div class="transform-rate" v-if="showRate">
              <span class="rate__title">Taux de transformation&nbsp;:</span>
              <span class="rate__total">{{
                $formatPercent(conversionRate)
              }}</span>
            </div>
            <div class="mt-5" v-if="showDays">
              <days-data
                v-bind="$keysToCamel(this.chartData || {})"
              ></days-data>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stats from '@/mixins/stats'
import { mapState } from 'vuex'
import DaysData from '@/components/statistics/DaysData.vue'

export default {
  components: {
    DaysData,
  },
  mixins: [Stats],
  props: {
    showRate: Boolean,
    showDays: Boolean,
    horizontal: Boolean,
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
}
</script>

<style lang="scss" scoped>
h4 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.transform-rate {
  text-align: center;

  .rate__total {
    font-size: 4rem;
  }
}

.days-avg {
  margin-top: 2rem;
  text-align: center;
  .days-avg__number {
    font-size: 2rem;
  }
  .days-avg__text {
    font-size: 1.1rem;
    font-weight: 600;
  }
}

.card {
  &.horizontal {
    .additional-data {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .transform-rate {
      display: flex;
      flex-direction: column;
    }

    .rate__title {
      font-weight: normal;
      font-size: 1.2rem;
    }
  }

  &:not(.horizontal) {
    /deep/ .chart__body {
      @include desktop {
        width: 50%;
      }
    }

    /deep/ .chart__lead .centered-text {
      font-size: 1.8rem;
    }

    .rate__title {
      margin-right: 1rem;
    }
  }
}
</style>
