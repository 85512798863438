<template>
  <b-button
    @click="onDelete"
    :title="softDelete ? 'Abandonner' : 'Supprimer'"
    :icon-left="softDelete ? 'archive-outline' : 'trash-can-outline'"
    :loading="loading"
  ></b-button>
</template>

<script>
export default {
  props: {
    id: [String, Number],
    confirmTitle: String,
    confirmMessage: String,
    successMessage: String,
    softDelete: Boolean,
  },
  inject: ['model'],
  data: () => ({
    loading: false,
  }),
  methods: {
    onDelete() {
      this.$buefy.dialog.confirm({
        title: this.confirmTitle,
        message: this.confirmMessage,
        confirmText: this.softDelete ? 'Abandonner' : 'Supprimer',
        cancelText: 'Annuler',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          this.loading = true

          try {
            await this.$axios.delete(`/api/${this.model}/${this.id}`)
          } finally {
            this.loading = false
          }

          this.$buefy.toast.open({
            message: this.successMessage,
            type: 'is-success',
            position: 'is-bottom',
            duration: 3000,
          })

          this.$emit('deleted')
        },
      })
    },
  },
}
</script>
