<template>
  <b-button
    icon-left="pencil-outline"
    tag="router-link"
    :to="`/${model}/${id}/edit`"
    title="Editer"
  >
  </b-button>
</template>

<script>
export default {
  props: {
    id: [String, Number],
  },
  inject: ['model'],
}
</script>
