<template>
  <div class="container is-fluid">
    <h1>{{ $route.meta.title }}</h1>

    <base-data-table
      model="users"
      ref="datatable"
      v-if="user"
      :columns="columns"
      :disable-import="user.is_manager"
      disable-search
    >
      <span slot="import-label">Importer des utilisateurs</span>
      <span slot="create-label">
        <span>Ajouter un utilisateur</span>
      </span>

      <template v-slot:filter-partner_id="props">
        <base-autocomplete
          size="is-small"
          v-model="props.filters[props.column.field]"
          model="partners"
          placeholder="Partenaire"
          :text="(model) => model.name"
        >
        </base-autocomplete>
      </template>

      <template v-slot:filter-entity_id="props">
        <base-autocomplete
          size="is-small"
          v-model="props.filters[props.column.field]"
          model="entities"
          placeholder="Entités"
          :text="(model) => model.name"
        >
        </base-autocomplete>
      </template>

      <template v-slot:field-role="props">
        <span class="tag">
          {{ roles[props.row.role] }}
        </span>
      </template>

      <template v-slot:field-regional_service_slug="props">
        <span class="regional_service_slug">
          {{ services[props.row.regional_service_slug] }}
        </span>
      </template>

      <template v-slot:field-active="props">
        <base-boolean
          :value="props.row.is_active"
          true-value="Actif"
          :false-value="
            props.row.is_profile_active ? 'Inactif' : 'Profil inactif'
          "
        ></base-boolean>
      </template>

      <template v-slot:field-last_login_at="props">
        {{ $formatDate(props.row.last_login_at, 'dd/MM/yyyy HH:mm') }}
      </template>

      <template v-slot:row-actions="props">
        <email-button v-if="isNotSameUser(props.row)" :user="props.row">
        </email-button>
        <base-edit-button :id="props.row.id"></base-edit-button>
        <impersonate-button
          v-if="user.is_admin && isNotSameUser(props.row)"
          :user="props.row"
        >
        </impersonate-button>
        <base-toggle-button
          v-if="isNotSameUser(props.row)"
          :id="props.row.id"
          :value="props.row.active"
          field="active"
          @toggled="loadAsyncData()"
          :confirm-title="
            (value) =>
              value ? `Réactiver l'utilisateur` : `Désactiver l'utilisateur`
          "
          :confirm-message="
            (value) =>
              value
                ? `Etes-vous sûr de vouloir réactiver l'utilisateur ${props.row.name} ?`
                : `Etes-vous sûr de vouloir désactiver l'utilisateur ${props.row.name} ?`
          "
          :success-message="
            (value) =>
              value
                ? `Utilisateur ${props.row.name} activé`
                : `Utilisateur ${props.row.name} désactivé`
          "
          icon-activate="archive-outline"
          icon-desactivate="archive"
        ></base-toggle-button>
      </template>
    </base-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EmailButton from '@/components/users/buttons/UserEmailButton.vue'
import ImpersonateButton from '@/components/users/buttons/ImpersonateButton.vue'

export default {
  components: {
    EmailButton,
    ImpersonateButton,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      roles: (state) => state.enums.roles,
      services: (state) => state.enums.services,
    }),
    columns() {
      return [
        {
          field: 'regional_service_slug',
          label: 'Caisse régionale',
          searchable: true,
          type: 'select',
          options: this.services,
          sortable: true,
          hidden: !this.user.is_super_admin,
        },
        {
          field: 'role',
          label: 'Rôle',
          searchable: true,
          type: 'select',
          sortable: true,
          options: this.roles,
          hidden: !this.user.is_admin && !this.user.is_penpal,
        },
        {
          field: 'last_name',
          label: 'Nom',
          searchable: true,
          sortable: true,
        },
        {
          field: 'first_name',
          label: 'Prénom',
          searchable: true,
          sortable: true,
        },
        {
          field: 'email',
          label: 'Email',
          searchable: true,
        },
        {
          field: 'active',
          label: 'Statut',
          searchable: true,
          type: 'select',
          options: {
            true: 'Actif',
            false: 'Inactif',
          },
        },
        // {
        //   field: "last_login_at",
        //   label: "Dernière connexion",
        //   sortable: true,
        //   type: "date",
        //   hidden: !this.user.is_admin,
        // },
      ]
    },
  },
  methods: {
    loadAsyncData() {
      this.$refs.datatable.loadAsyncData()
    },
    isNotSameUser(user) {
      return user.id !== this.user.id
    },
  },
}
</script>
