import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from '@/plugins/axios'

import MainLayout from '../layouts/Main'

import Login from '../views/Login'
import PasswordChange from '../views/PasswordChange'
import PasswordReset from '../views/PasswordReset'
import Error from '../views/Error'
import Home from '../views/Home'
import Legal from '../views/Legal'
import PersonalData from '../views/PersonalData'

import UserList from '../views/users/List'
import UserCreate from '../views/users/Create'
import UserEdit from '../views/users/Edit'

import ComplaintList from '../views/complaints/List'
import ComplaintCreate from '../views/complaints/Create'
import ComplaintEdit from '../views/complaints/Edit'
import ComplaintShow from '../views/complaints/Show'

import SftpImport from '../views/sftp/Import'

import ArchiveList from '../views/archives/List'

let appTitle = 'Groupama GBH'

let resources = {
  users: "L'utilisateur",
  complaints: 'La réclamation',
}

const redirectToErrorPage = (e, model, { next }) => {
  switch (e.response.status) {
    case 403:
      next({
        name: '403',
        params: {
          status: 403,
          message: `${resources[model]} n'est plus disponible`,
        },
      })
      break
    case 404:
      next({
        name: '404',
        params: {
          status: 404,
          message: `${resources[model]} n'est plus disponible`,
        },
      })
      break
    case 500:
      next({
        name: '500',
        params: {
          status: 500,
          message: 'Erreur serveur',
        },
      })
      break
  }
}

const redirectWithItem = async (model, title, { to, next, url, headers }) => {
  try {
    let { data } = await axios.get(url || `/api/${model}/${to.params.id}`, {
      headers,
    })

    to.meta.model = data
    to.meta.title = typeof title === 'string' ? title : title(data)
    document.title = `${to.meta.title} | ${appTitle}`
    next()
  } catch (e) {
    redirectToErrorPage(e, model, { id: to.params.id, next })
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
      redirect: true,
    },
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        name: 'Home',
        component: Home,
        meta: {
          title: 'Tableau de bord',
        },
      },
      {
        path: '/legal',
        name: 'Legal',
        component: Legal,
        meta: {
          title: 'Mentions légales',
        },
      },
      {
        path: '/personal-data',
        name: 'PersonalData',
        component: PersonalData,
        meta: {
          title: 'Données personnelles',
        },
      },
      {
        path: '/password/change',
        name: 'PasswordChange',
        props: true,
        component: PasswordChange,
        meta: {
          title: 'Modifier mon mot de passe',
        },
      },
      {
        path: '/password/reset/:token',
        name: 'PasswordReset',
        props: true,
        component: PasswordReset,
        meta: {
          public: true,
          redirect: true,
          title: 'Modifier mon mot de passe',
        },
      },
      {
        path: '/users',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        meta: {
          deny: (user) => user.is_user,
        },
        children: [
          {
            path: '',
            name: 'UserList',
            component: UserList,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'Liste des utilisateurs'

              if (to.query.partner_id) {
                to.meta.filter = { partner_id: to.query.partner_id }

                try {
                  let { data } = await axios.get(
                    `/api/partners/${to.query.partner_id}`
                  )
                  to.meta.title = `Liste des utilisateurs du partenaire ${data.name}`
                } catch (e) {
                  redirectToErrorPage(e, 'partners', {
                    id: to.query.partner_id,
                    next,
                  })
                }
              } else if (to.query.entity_id) {
                to.meta.filter = { entity_id: to.query.entity_id }

                try {
                  let { data } = await axios.get(
                    `/api/entities/${to.query.entity_id}`
                  )
                  to.meta.title = `Liste des utilisateurs de l'entité ${data.name}`
                } catch (e) {
                  redirectToErrorPage(e, 'entities', {
                    id: to.query.entity_id,
                    next,
                  })
                }
              }

              document.title = `${to.meta.title} | ${appTitle}`
              next()
            },
          },
          {
            path: 'create',
            name: 'UserCreate',
            component: UserCreate,
            meta: {
              title: 'Créer un utilisateur',
            },
          },
          {
            path: ':id/edit',
            name: 'UserEdit',
            component: UserEdit,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'users',
                (model) => `Editer l'utilisateur "${model.name}"`,
                {
                  to,
                  next,
                }
              ),
          },
        ],
      },
      {
        path: '/complaints',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'ComplaintList',
            component: ComplaintList,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'Liste des réclamations'

              document.title = `${to.meta.title} | ${appTitle}`
              next()
            },
          },
          {
            path: 'create',
            name: 'ComplaintCreate',
            component: ComplaintCreate,
            meta: {
              title: 'Créer une réclamation',
            },
          },
          {
            path: ':id',
            name: 'ComplaintShow',
            props: true,
            component: ComplaintShow,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'complaints',
                (model) => `Réclamation ${model.firstname} ${model.lastname}`,
                {
                  to,
                  next,
                }
              ),
          },
          {
            path: ':id/edit',
            name: 'ComplaintEdit',
            component: ComplaintEdit,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'complaints',
                (model) =>
                  `Editer la réclamation "${model.lastname} ${model.firstname}"`,
                {
                  to,
                  next,
                }
              ),
          },
          {
            path: ':id/:token',
            name: 'ComplaintShowPublic',
            component: ComplaintShow,
            props: true,
            meta: {
              public: true,
            },
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'complaints',
                (model) => `Réclamation "${model.lastname} ${model.firstname}"`,
                {
                  to,
                  next,
                  headers: { token: to.params.token },
                }
              ),
          },
        ],
      },
      {
        path: '/archives',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'ArchiveList',
            component: ArchiveList,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'Liste des archives'

              document.title = `${to.meta.title} | ${appTitle}`
              next()
            },
          },
          {
            path: ':id',
            name: 'ArchiveShow',
            props: true,
            component: ComplaintShow,
            beforeEnter: (to, from, next) =>
              redirectWithItem(
                'complaints',
                (model) => `Réclamation ${model.firstname} ${model.lastname}`,
                {
                  to,
                  next,
                }
              ),
          },
        ],
      },
      {
        path: '/sftp',
        component: {
          render(c) {
            return c('router-view')
          },
        },
        meta: {
          deny: (user) => !user.is_super_admin,
        },
        children: [
          {
            path: '',
            name: 'SftpImport',
            component: SftpImport,
            beforeEnter: async (to, from, next) => {
              to.meta.filter = null
              to.meta.title = 'SFTP Import'

              document.title = `${to.meta.title} | ${appTitle}`
              next()
            },
          },
        ],
      },
      {
        path: '/403',
        name: '403',
        component: Error,
        props: true,
      },
      {
        path: '/404',
        name: '404',
        component: Error,
        props: true,
      },
      {
        path: '/500',
        name: '500',
        component: Error,
        props: true,
      },
      {
        path: '*',
        redirect: '404',
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  // Titre générique
  document.title =
    to.meta && to.meta.title ? `${to.meta.title} | ${appTitle}` : appTitle

  // Chargement des enums
  store.dispatch('loadEnums')

  // Utilisateur courant
  let user = await store.dispatch('auth/checkAuth')

  if (!to.meta.public) {
    if (!user) {
      return next({ name: 'Login' })
    }

    // Test des accès
    to.matched.forEach((r) => {
      if (r.meta.deny && r.meta.deny(user)) {
        return next({
          name: '403',
          params: {
            status: 403,
            message: `Accès à cette page non autorisé`,
          },
        })
      }
    })

    return next()
  }

  if (user && to.meta.redirect) {
    return next({ name: 'Home' })
  }

  next()
})

router.redirectWithItem = redirectWithItem

export default router
