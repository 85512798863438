<template>
  <div>
    <doughnut-chart
      :data="data"
      :labels="getLabels"
      :options="{
        cutout: 80,
        plugins: { legend: { display: false }, datalabels: { display: false } },
        responsive: true,
      }"
    >
      <slot v-if="!hideTotal">
        {{ $formatNumber(total) }}<br /><span v-if="total > 1">réclam.</span>
        <span v-else>réclam.</span>
      </slot>
    </doughnut-chart>
  </div>
</template>

<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { mapState } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DoughnutChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: Number,
    hideTotal: Boolean,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      statuses: (state) => state.enums.complaint.status,
    }),
    getLabels() {
      let labels = Object.keys(this.statuses)

      return labels.map((prop) => {
        return {
          prop,
          label: this.statuses[prop],
          color: this.$statusColors[prop],
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.chart::v-deep {
  .chart__legend {
    width: max-content;
  }
}
</style>
