<template>
  <div class="container">
    <h1>{{ $route.meta.title }}</h1>
    <form @submit.prevent="submit">
      <section>
        <b-field>
          <b-upload v-model="dropFiles" multiple drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Déposer vos fichiers ici</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div class="tags">
          <span
            v-for="(file, index) in dropFiles"
            :key="index"
            class="tag is-primary"
          >
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile(index)"
            ></button>
          </span>
        </div>
      </section>
      <div class="mt-3">
        <b-button type="is-warning" native-type="submit" :loading="loading">
          Importer
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dropFiles: [],
    }
  },
  methods: {
    async submit() {
      this.loading = true
      let formData = new FormData()
      this.dropFiles.forEach((file) => {
        console.log(file)
        formData.append('files[]', file)
      })

      try {
        await this.$axios.post(`/api/complaints/sftp`, formData)
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
  },
}
</script>
