<template>
  <b-button type="is-warning" @click="back" class="mr-2">Annuler</b-button>
</template>

<script>
export default {
  name: 'BackButton',
  methods: {
    back() {
      this.$router.back()
    },
  },
}
</script>
