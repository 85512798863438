import Vue from 'vue'
import { format } from 'date-fns'
import truncate from 'lodash/truncate'

Vue.prototype.$formatDate = (dateValue, formatValue) =>
  dateValue ? format(new Date(dateValue), formatValue) : '-'

Vue.prototype.$formatPercent = (value, decimals = 0) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    minimumFractionDigits: decimals,
  }).format(value || 0)
}

Vue.prototype.$formatNumber = (value) => {
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
  }).format(value || 0)
}

Vue.prototype.$formatCurrency = (value) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }).format(value || 0)
}

Vue.prototype.$truncate = (value, length = 100) => {
  return truncate(value, { length })
}

Vue.prototype.$keysToCamel = (o) => {
  const toCamel = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  let n = {}

  Object.keys(o).forEach((k) => {
    n[toCamel(k)] = o[k]
  })

  return n
}

Vue.prototype.$criticalityColors = {
  LOW: '#209cee',
  MEDIUM: '#ffdd57',
  HIGH: '#ea5e34',
}
Vue.prototype.$statusColors = {
  NEW: '#209cee',
  DEALING: '#ffdd57',
  PROCESSED: '#ea5e34',
  DROPPED: '#b86bff',
}
