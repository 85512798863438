<template>
  <b-datepicker
    :id="id"
    :icon="icon"
    :size="size"
    trap-focus
    :value="getDateValue"
    :editable="editable"
    :range="range"
    :placeholder="placeholder"
    :position="position"
    @input="onChange"
  >
    <slot></slot>
  </b-datepicker>
</template>

<script>
export default {
  props: {
    value: [String, Date, Array],
    id: String,
    icon: String,
    placeholder: String,
    position: String,
    size: String,
    editable: Boolean,
    range: Boolean,
  },
  computed: {
    getDateValue() {
      if (!this.value) {
        return null
      }
      if (this.range) {
        return this.value.map((v) => new Date(v))
      }
      if (this.value instanceof Date) {
        return this.value
      }
      return new Date(this.value)
    },
  },
  methods: {
    onChange(value) {
      this.$emit(
        'input',
        this.range
          ? value.map((v) => this.$formatDate(v, 'yyyy-MM-dd'))
          : this.$formatDate(value, 'yyyy-MM-dd')
      )
    },
  },
}
</script>
