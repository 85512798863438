<template>
  <div>
    <b-field
      label="Mot de passe"
      label-for="password"
      :type="{ 'is-danger': !passwordRegex }"
      message="Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre, un caractère spécial et faire 12 caractères."
    >
      <b-input id="password" type="password" required v-model="form.password">
      </b-input>
    </b-field>

    <b-field
      label="Confirmer le mot de passe"
      label-for="password_confirmation"
      :type="{ 'is-danger': !passwordConfirmSame }"
      :message="{
        'Les mots de passe doivent correspondre.': !passwordConfirmSame,
      }"
    >
      <b-input
        id="password_confirmation"
        type="password"
        required
        v-model="form.password_confirmation"
      >
      </b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'Password',
  data() {
    return {
      form: {
        password: null,
        password_confirmation: null,
      },
      passwordRegex: true,
      passwordConfirmSame: true,
    }
  },
  watch: {
    'form.password'(newValue) {
      let password = newValue
      /**
       * \d => at least one digit
       * [a-z] => at least one lower case alpha
       * [A-Z] => at least one upper case alpha
       * \W => at least one special character
       * [a-zA-Z\d\W]{12,} => at least 12 characters with precedent regex
       */
      var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])[a-zA-Z\d\W]{12,}$/
      if (regex.test(password)) {
        this.passwordRegex = true
      } else {
        this.passwordRegex = false
      }
    },
    'form.password_confirmation'(newValue) {
      if (newValue === this.form.password) {
        this.passwordConfirmSame = true
      } else {
        this.passwordConfirmSame = false
      }
    },
  },
}
</script>
