<template>
  <div class="users">
    <div class="user" v-for="(user, i) in users" :key="i">
      <div class="section-header mb-5">
        <h3 class="has-text-weight-bold">
          {{ i === 0 ? 'Compte utilisateur' : `Autre compte utilisateur ${i}` }}
        </h3>
        <a
          href="javascript:void(0)"
          v-if="i > 0 && user.is_new"
          @click="onRemove(i)"
        >
          <b-icon icon="close"></b-icon>
        </a>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <div v-if="!userData.is_manager" class="column">
              <base-field label="Rôle*" :name="`users.${i}.role`">
                <b-select
                  :id="`users.${i}.role`"
                  placeholder="Sélectionner un rôle"
                  expanded
                  v-model="user.role"
                  required
                >
                  <option
                    v-for="(role, rolekey) in rolesData"
                    :value="rolekey"
                    :key="rolekey"
                  >
                    {{ role }}
                  </option>
                </b-select>
              </base-field>
            </div>
            <div v-if="user.role !== 'SUPER_ADMIN'" class="column">
              <base-field
                v-if="userData.is_super_admin"
                label="Caisse régionale*"
                :name="`users.${i}.regional_service_slug`"
              >
                <b-select
                  :id="`users.${i}.regional_service_slug`"
                  placeholder="Sélectionner une Caisse régionale"
                  expanded
                  v-model="user.regional_service_slug"
                  required
                >
                  <option
                    v-for="(service, servicekey) in services"
                    :value="servicekey"
                    :key="servicekey"
                  >
                    {{ service }}
                  </option>
                </b-select>
              </base-field>
            </div>
          </div>
          <div v-if="user.role === 'USER'" class="columns">
            <div class="column">
              <base-field
                v-if="!userData.is_manager"
                label="Manager*"
                :name="`users.${i}.manager_id`"
              >
                <base-autocomplete
                  :id="`users.${i}.manager_id`"
                  v-model="user.manager_id"
                  model="users"
                  placeholder="Email"
                  :text="(model) => model.email"
                  :filter="{ role: 'MANAGER' }"
                >
                </base-autocomplete>
              </base-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <base-field label="Nom*" :name="`users.${i}.last_name`">
                <b-input
                  :id="`users.${i}.last_name`"
                  v-model="user.last_name"
                ></b-input>
              </base-field>
            </div>
            <div class="column">
              <base-field label="Prénom*" :name="`users.${i}.first_name`">
                <b-input
                  :id="`users.${i}.first_name`"
                  v-model="user.first_name"
                ></b-input>
              </base-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <base-field label="Email*" :name="`users.${i}.email`">
                <b-input
                  :id="`users.${i}.email`"
                  v-model="user.email"
                  type="email"
                ></b-input>
              </base-field>
            </div>
            <div class="column">
              <base-field label="Mot de passe" :name="`users.${i}.password`">
                <b-input
                  :id="`users.${i}.password`"
                  v-model="user.password"
                  type="password"
                ></b-input>
              </base-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <a class="link" href="javascript:void(0)" @click="onAdd">
        Ajouter un autre compte
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    default: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    users: [
      {
        first_name: null,
        last_name: null,
        email: null,
        role: null,
        regional_service_slug: null,
        password: null,
        manager_id: null,
      },
    ],
    rolesData: {},
  }),
  watch: {
    users: {
      handler(newVal) {
        this.$emit('input', newVal)
        if (this.userData.is_manager) {
          newVal.forEach((element) => {
            element.role = 'USER'
          })
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (!this.userData.is_super_admin) {
      // eslint-disable-next-line no-unused-vars
      for (const [key] of Object.entries(this.roles)) {
        if (!this.userData.is_super_admin) {
          this.rolesData = this.roles
          delete this.rolesData['SUPER_ADMIN']
        }
        if (this.userData.is_penpal) {
          this.rolesData = this.roles
          delete this.rolesData['ADMIN']
          delete this.rolesData['PENPAL']
        }
        if (this.userData.is_manager) {
          this.rolesData = this.roles
          delete this.rolesData['ADMIN']
          delete this.rolesData['PENPAL']
          delete this.rolesData['MANAGER']
        }
      }
    } else {
      this.rolesData = this.roles
    }
  },
  computed: {
    ...mapState({
      userData: (state) => state.auth.user,
      roles: (state) => state.enums.roles,
      services: (state) => state.enums.services,
    }),
  },
  methods: {
    onAdd() {
      this.users.push({
        is_new: true,
        last_name: null,
        first_name: null,
        email: null,
        role: null,
        regional_service_slug: null,
        password: null,
        manager_id: null,
      })
    },
    onRemove(index) {
      this.users = this.users.filter((u, i) => index !== i)
    },
  },
}
</script>

<style lang="scss" scoped>
.users {
  margin-top: 2rem;
  margin-bottom: 6rem;

  .user {
    margin-bottom: 2rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $orange;
    }
  }
}
</style>
