<template>
  <div>
    <div class="columns admin__body">
      <div
        class="column is-one-third"
        v-for="(name, value) in chartData"
        :key="value"
      >
        <h3>{{ value }}</h3>
        <stats-card
          class="stats"
          :chart-data="chartData[value] || {}"
        ></stats-card>
      </div>
    </div>
    <!-- <div class="columns admin__footer" v-if="user.is_admin">
      <div class="column" v-for="link in createLinks" :key="link.type">
        <router-link :to="`/${link.type}/create`">
          <b-icon icon="plus" size="is-large"></b-icon>
          {{ link.label }}
        </router-link>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StatsCard from '@/components/cards/StatsCard.vue'

export default {
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    StatsCard,
  },
  data: () => ({
    createLinks: [
      {
        type: 'partners',
        label: 'Un partenaire',
      },
      {
        type: 'entities',
        label: 'Une entité',
      },
      {
        type: 'users',
        label: 'Un utilisateur',
      },
      {
        type: 'posts',
        label: 'Une actualité',
      },
    ],
  }),

  computed: {
    ...mapState({
      services: (state) => state.enums.services,
      user: (state) => state.auth.user,
    }),
  },
}
</script>

<style lang="scss" scoped>
.admin__body {
  flex-wrap: wrap;
  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.admin__footer {
  a {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    background: $white;
    color: inherit;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    transition: all 0.2s ease-out;

    .icon {
      margin-right: 0.5rem;
    }

    &:hover {
      background: $primary;
      color: $white;
    }
  }
}
</style>
