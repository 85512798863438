<template>
  <b-navbar transparent>
    <template slot="start" v-if="user">
      <b-navbar-item
        v-for="(item, i) in items.filter((i) => !i.hidden)"
        :key="i"
        tag="router-link"
        :to="{ name: item.link }"
      >
        {{ item.text }}
      </b-navbar-item>
    </template>
    <template slot="end">
      <div class="nav-end">
        <!-- <b-button
          v-if="user.is_super_admin && $route.name === 'ComplaintList'"
          @click="resetList"
          class="mr-3"
        >
          Reset
        </b-button> -->
        <section class="datetime">
          <div v-if="$route.name === 'ComplaintList'">
            Dernière importation : le
            <span v-if="complaintsImportDate">
              {{ $formatDate(complaintsImportDate.created_at, 'dd/MM/yyyy') }}
              à {{ $formatDate(complaintsImportDate.created_at, 'HH:mm:ss') }}
            </span>
            <span v-else> - </span>
          </div>
        </section>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      complaintsImportDate: (state) => state.complaintsImportDate,
    }),
    items() {
      return [
        {
          text: 'Accueil',
          link: 'Home',
        },
        {
          text: 'Les utilisateurs',
          link: 'UserList',
          hidden: this.user.is_user,
        },
        {
          text: 'Les réclamations',
          link: 'ComplaintList',
        },
        {
          text: 'Les archives',
          link: 'ArchiveList',
        },
      ].filter((item) => {
        /**
         * Pris en compte des permissions au niveau des routes
         */
        let { route } = this.$router.resolve({ name: item.link })

        for (let r of route.matched) {
          if (r.meta.deny && r.meta.deny(this.user)) {
            return false
          }
        }

        return true
      })
    },
  },
  methods: {
    async resetList() {
      await this.$axios.post('/api/complaints/reset')
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-end {
  display: flex;
  align-items: center;
}

.navbar {
  background: $green;

  @include tablet {
    height: 70px;
  }
}

.navbar-item {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem;
  margin: 0.75rem;
  border-bottom: solid 1px transparent;
  transition: all 0.2s ease-out;

  &:hover,
  &.router-link-active {
    background: transparent;
    color: $black;
    border-bottom-color: $black;
  }

  @include desktop {
    color: $white;

    &:hover,
    &.router-link-active {
      color: $white;
      border-bottom-color: $white;
    }
  }
}

/deep/ .navbar-burger {
  color: $white;
}

.datetime {
  color: $white;
  font-style: italic;
  display: flex;
  padding: 1rem;

  & div {
    margin: auto;
  }
}
</style>
