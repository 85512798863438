<template>
  <div>
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div v-if="!user.is_manager" class="column">
            <base-field label="Rôle*" name="role">
              <b-select
                id="role"
                placeholder="Sélectionner un rôle"
                expanded
                v-model="form.role"
                required
              >
                <option
                  v-for="(role, rolekey) in rolesData"
                  :value="rolekey"
                  :key="rolekey"
                >
                  {{ role }}
                </option>
              </b-select>
            </base-field>
          </div>
          <div v-if="form.role !== 'SUPER_ADMIN'" class="column">
            <base-field
              v-if="user.is_super_admin"
              label="Caisse régionale*"
              name="regional_service_slug"
            >
              <b-select
                id="regional_service_slug"
                placeholder="Sélectionner une Caisse régionale"
                expanded
                v-model="form.regional_service_slug"
                required
              >
                <option
                  v-for="(service, servicekey) in services"
                  :value="servicekey"
                  :key="servicekey"
                >
                  {{ service }}
                </option>
              </b-select>
            </base-field>
          </div>
        </div>
        <div v-if="form.role === 'USER'" class="columns">
          <div class="column">
            <base-field
              v-if="!user.is_manager"
              label="Manager*"
              name="manager_id"
            >
              <base-autocomplete
                id="manager_id"
                v-model="form.manager_id"
                model="users"
                placeholder="Email"
                :text="(model) => model.email"
                :filter="{ role: 'MANAGER' }"
              >
              </base-autocomplete>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Nom*" name="last_name">
              <b-input
                id="last_name"
                v-model="form.last_name"
                required
              ></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Prénom*" name="first_name">
              <b-input
                id="first_name"
                v-model="form.first_name"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Email*" name="email">
              <b-input
                id="email"
                v-model="form.email"
                type="email"
                required
              ></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Mot de passe" name="password">
              <b-input
                id="password"
                v-model="form.password"
                type="password"
              ></b-input>
            </base-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EditForm',
  props: {
    default: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        role: null,
        regional_service_slug: null,
        password: null,
        manager_id: null,
      },
      rolesData: {},
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.form = this.default
    if (!this.user.is_super_admin) {
      // eslint-disable-next-line no-unused-vars
      for (const [key] of Object.entries(this.roles)) {
        if (!this.user.is_super_admin) {
          this.rolesData = this.roles
          delete this.rolesData['SUPER_ADMIN']
        }
        if (this.user.is_penpal) {
          this.rolesData = this.roles
          delete this.rolesData['ADMIN']
          delete this.rolesData['PENPAL']
        }
        if (this.user.is_manager) {
          this.rolesData = this.roles
          delete this.rolesData['ADMIN']
          delete this.rolesData['PENPAL']
          delete this.rolesData['MANAGER']
        }
      }
    } else {
      this.rolesData = this.roles
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      roles: (state) => state.enums.roles,
      services: (state) => state.enums.services,
    }),
  },
}
</script>
