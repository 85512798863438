<template>
    <b-autocomplete
        :id="id"
        :data="data"
        :placeholder="placeholder"
        :custom-formatter="text"
        :loading="isFetching"
        :size="size"
        clearable
        v-model="label"
        @typing="getAsyncData"
        @select="onSelect"
        :keep-first="keepFirst"
    >
        <template slot-scope="props">
            <slot v-bind="props">
                {{ text(props.option) }}
            </slot>
        </template>
    </b-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import qs from 'qs'

export default {
    props: {
        id: String,
        value: {
            type: [Object, Number, String],
            default: () => {},
        },
        trackedBy: {
            type: String,
            default: 'id',
        },
        filter: {
            type: Object,
            default: () => {},
        },
        text: Function,
        model: String,
        size: String,
        placeholder: String,
        perPage: {
            type: Number,
            default: 10,
        },
        keepFirst: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            data: [],
            isFetching: false,
            label: null,
        }
    },
    watch: {
        label(newVal) {
            if (!newVal) {
                this.$emit('input', null)
            }
        },
        value: {
            handler(newVal) {
                if (newVal) {
                    this.getModel(newVal)
                } else {
                    this.label = null
                }
            },
            immediate: true,
        },
    },
    methods: {
        onSelect(option) {
            //console.log('onSelect', option)
            if (option) {
                this.$emit('input', option[this.trackedBy])
            }
            this.$emit('select', option)
        },
        async getModel(id) {
            //console.log('getModel', id)
            this.isFetching = true

            let { data } = await this.$axios.get(`/api/${this.model}/${id}`)

            this.label = this.text(data)
            this.isFetching = false
        },
        getAsyncData: debounce(async function (q) {
            //console.log('getAsyncData', q)
            if ( q!=undefined ) q = q.trim()
            if ( q==undefined || q.length===0 ) {
                this.data = []
                return
            }

            this.isFetching = true

            let { data } = await this.$axios.get(
                `/api/${this.model}?${qs.stringify({
                    filter: {
                        q,
                        ...this.filter,
                    },
                    perPage: this.perPage,
                    page: 1,
                })}`
            )

            this.data = data.data
            //console.log('getAsyncData', this.data)

            // S'il n'y a qu'une seule valeur et si elle correspond au texte entré, alors on sélectionne l'option.
            // Note : ceci est nécessaire en cas de copier/coller d'une adresse e-mail dans le champ
            // et de clic immédiat sur le bouton d'enregistrement sans tabulation, entrée ou clic dans un autre champ avant.
            if ( this.data.length===1 && this.data[0].email.toLowerCase()===q.toLowerCase() ) {
                this.onSelect(this.data[0])
            }

            this.isFetching = false
        }, 500),
    },
}
</script>
