<template>
    <base-model-form
        model="users"
        v-model="form"
        model-name="Utilisateur"
        redirect="/users"
        :default="model"
        :saving.sync="saving"
    >
        <div v-if="$route.fullPath.includes('create')">
            <create-embed-form
                :default="model ? model.users : []"
                @input="(v) => (form.users = v)"
            ></create-embed-form>
        </div>
        <div v-else>
            <edit-form
                :default="model ? model : {}"
                @input="(v) => (form.user = v)"
            />
        </div>

        <div class="submit-button mt-4">
            <div class="double-button">
                <back-button />
                <b-button native-type="submit" type="is-warning" :loading="saving">
                    <span v-if="model">Mettre à jour</span>
                    <span v-else>Ajouter l'utilisateur</span>
                </b-button>
            </div>
        </div>
    </base-model-form>
</template>

<script>
import CreateEmbedForm from '@/components/users/CreateEmbedForm.vue'
import { mapState } from 'vuex'
import EditForm from '@/components/users/EditForm.vue'
import BackButton from '@/components/base/buttons/BackButton.vue'

export default {
    components: { CreateEmbedForm, EditForm, BackButton },
    props: ['model'],
    data: () => ({
        form: {
            users: [],
            user: {
                first_name: null,
                last_name: null,
                email: null,
                role: null,
                regional_service_slug: null,
                password: null,
                manager_id: null,
            },
        },
        managers: null,
        saving: false,
        rolesData: {},
    }),
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            roles: (state) => state.enums.roles,
            services: (state) => state.enums.services,
        }),
    },
}
</script>
