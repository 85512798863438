<template>
  <form @submit.prevent="onSubmit()">
    <slot></slot>
  </form>
</template>

<script>
import pick from 'lodash/pick'

export default {
  props: {
    model: {
      type: String,
      required: true,
    },
    saving: Boolean,
    value: {
      type: Object,
      default: () => {},
    },
    default: {
      type: Object,
      default: () => {},
    },
    modelName: String,
    /**
     * Instant de gêne
     */
    modelFeminine: Boolean,
    redirect: [String, Object],
  },
  provide() {
    return {
      state: this.state,
    }
  },
  data: () => ({
    id: null,
    state: {
      errors: {},
    },
  }),
  watch: {
    default: {
      handler(newVal) {
        if (newVal) {
          this.id = newVal.id

          this.$emit('input', {
            ...this.value,
            ...pick(newVal, Object.keys(this.value)),
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSubmit() {
      this.submit()
    },
    async submit() {
      this.$emit('update:saving', true)

      try {
        await this.$axios({
          method: this.id ? 'patch' : 'post',
          url: this.id ? `/api/${this.model}/${this.id}` : `/api/${this.model}`,
          data: this.value,
        })

        this.$buefy.toast.open({
          duration: 5000,
          message: `${this.modelName} ${this.id ? 'modifié' : 'ajouté'}${
            this.modelFeminine ? 'e' : ''
          } avec succès`,
          position: 'is-bottom',
          type: 'is-success',
        })

        if (this.redirect) {
          this.$router.push(this.redirect)
        }
      } catch (e) {
        if (e.response.status === 422) {
          this.state.errors = e.response.data.errors
        }
      } finally {
        this.$emit('update:saving', false)
      }
    },
  },
}
</script>
