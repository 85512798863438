<template>
  <base-model-form
    model="complaints"
    v-model="form"
    model-name="Réclamation"
    redirect="/complaints"
    :model-feminine="true"
    :default="model"
    :saving.sync="saving"
  >
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <base-field label="Nom*" name="lastname">
              <b-input
                id="last_name"
                v-model="form.lastname"
                required
              ></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Prénom*" name="firstname">
              <b-input
                id="firstname"
                v-model="form.firstname"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field
              label="IDGRC / N° Client*"
              name="idgrc"
              :mask="'########'"
            >
              <b-input id="idgrc" v-model="form.idgrc" required></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field label="Code postal - Ville*" name="postcode_city">
              <base-place
                id="postcode_city"
                v-model="form.postcode_city"
                required
              ></base-place>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field
              label="Téléphone portable*"
              name="phone"
              :mask="{
                mask: '0Z ## ## ## ##',
                tokens: { Z: { pattern: /^[1-9]\d*$/ } },
              }"
              class="red-helper"
              alert="(Renseigné par le client lors de la souscription du contrat)"
            >
              <b-input id="phone" v-model="form.phone" required></b-input>
            </base-field>
          </div>
          <div class="column">
            <base-field
              label="Email*"
              name="email"
              class="red-helper"
              alert="(Renseigné par le client lors de la souscription du contrat)"
            >
              <b-input
                id="email"
                v-model="form.email"
                type="email"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Date installation" name="installed_at">
              <base-datepicker
                id="installed_at"
                icon="calendar-today"
                v-model="form.installed_at"
                position="is-top-right"
              ></base-datepicker>
            </base-field>
          </div>
          <div class="column">
            <base-field
              label="Criticité*"
              name="criticality"
              tooltip="<b>Faible</b> : rdv reporté, chute d'un détecteur, un avantage qui n'apparaît pas sur la facture...<br><b>Moyen</b> : plusieurs reports de RV, déclenchement injustifié d'un détecteur...<br><b>Haute</b> : RV installation non honoré, posture technicien inappropriée, dégradation lors de l’installation, propos inappropriés sur l’offre, système non fonctionnel après plusieurs interventions..."
            >
              <base-radios
                id="criticality"
                :data="criticalityData"
                name="criticality"
                v-model="form.criticality"
                required
              />
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field label="Réclamation*" name="types">
              <base-checkboxes
                id="types"
                :data="typesData"
                v-model="form.types"
                required
              />
            </base-field>
          </div>
          <div class="column">
            <base-field
              label="Pièces jointes"
              name="attachments"
              message="Vous pouvez déposer jusqu'à 3 pièces jointes (3Mo max chacune)"
            >
              <base-upload
                v-if="form.current_attachments.length < 3"
                id="attachments"
                v-model="form.attachments"
                multiple
                drag-drop
              >
              </base-upload>
              <base-file-collection
                :files="form.current_attachments"
                deletable
              />
            </base-field>
            <div v-for="i in 3" :key="i.id">
              <base-field :name="`attachments.${i - 1}.base64`"> </base-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <base-field
              v-if="user.is_super_admin"
              label="Caisse régionale*"
              name="regional_service_slug"
              message="Vous êtes administrateur, vous devez spécifier la caisse régionale de rattachement."
            >
              <base-select
                v-if="regionalServices"
                id="regional_service_slug"
                v-model="form.regional_service_slug"
                :model="services"
                placeholder="Sélectionner votre caisse régionale"
                :text="(model) => model.name"
                enum-data
                required
              ></base-select>
            </base-field>
          </div>
          <div class="column">
            <base-field
              v-if="user.is_admin"
              label="Statut*"
              name="status"
              message="Vous êtes administrateur, vous pouvez changer le statut."
            >
              <base-select
                id="status"
                v-model="form.status"
                :model="status"
                placeholder="Sélectionner le statut"
                :text="(model) => model.name"
                enum-data
                required
              ></base-select>
            </base-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-full">
            <base-field label="Description*" name="description">
              <b-input
                id="description"
                v-model="form.description"
                type="textarea"
                maxlength="2000"
                required
              ></b-input>
            </base-field>
          </div>
        </div>
      </div>
      <div class="submit-button">
        <div class="double-button">
          <back-button />
          <b-button native-type="submit" type="is-warning" :loading="saving">
            <span v-if="model">Mettre à jour</span>
            <span v-else>Envoyer une réclamation</span>
          </b-button>
        </div>
      </div>
    </div>
  </base-model-form>
</template>

<script>
import BackButton from '@/components/base/buttons/BackButton.vue'
import { mapState } from 'vuex'

export default {
  components: { BackButton },
  props: ['model'],
  data: () => ({
    isDev: process.env.NODE_ENV === 'development',
    form: {
      regional_service_slug: null,
      firstname: null,
      lastname: null,
      email: null,
      idgrc: null,
      id_trace: null,
      postcode_city: null,
      phone: null,
      installed_at: null,
      criticality: null,
      types: [],
      status: null,
      status_new_at: null,
      description: null,
      user_id: null,
      attachments: [],
      current_attachments: [],
      is_global: false,
    },
    formFake: {
      regional_service_slug: 'gca',
      firstname: 'Prénom',
      lastname: 'Nom',
      email: 'user@email.com',
      idgrc: '12345678',
      id_trace: null,
      postcode_city: '35000 - Rennes',
      phone: '06 00 00 00 00',
      installed_at: '01/01/2021',
      criticality: 'LOW',
      types: ['INSTALLATION', 'EQUIPMENT', 'APPLICATION'],
      description:
        'Est labore proident consequat amet exercitation anim deserunt enim occaecat officia consequat nisi sint.',
      user_id: null,
      attachments: [],
      current_attachments: [],
      status: 'NEW',
      status_new_at: null,
      is_global: false,
    },
    saving: false,
    regionalServices: false,
  }),
  mounted() {
    // create route
    if (!this.$route.path.includes('edit')) {
      // set status
      this.form.status = 'NEW'
      this.form.status_new_at = new Date()
      if (this.isDev) {
        this.form = this.formFake
      }
    }
    /**
     * Si l'utilisateur est un super_admin, il n'a pas de caisse régionale, ajout du select
     * Sinon la caisse est celle de l'utilisateur
     */
    if (!this.user.regional_service_slug) {
      this.regionalServices = true
    } else {
      this.form.regional_service_slug = this.user.regional_service_slug
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      roles: (state) => state.enums.roles,
      services: (state) => state.enums.services,
      criticality: (state) => state.enums.complaint.criticality,
      status: (state) => state.enums.complaint.status,
      types: (state) => state.enums.complaint.type,
    }),
    typesData() {
      let types = []
      for (const [key, value] of Object.entries(this.types)) {
        types.push({
          key,
          value,
        })
      }

      return types
    },
    criticalityData() {
      let criticality = []
      for (const [key, value] of Object.entries(this.criticality)) {
        criticality.push({
          key,
          value,
        })
      }

      return criticality
    },
  },
}
</script>
