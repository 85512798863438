<template>
  <doughnut-chart
    :data="data"
    :labels="getLabels"
    :options="{
      cutout: 120,
      plugins: { legend: { display: false }, datalabels: { display: false } },
      responsive: true,
    }"
    :count-formatter="(value) => $formatCurrency(value)"
  >
    <slot v-if="!hideTotal">{{ $formatCurrency(total) }}</slot>
  </doughnut-chart>
</template>

<script>
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { mapState } from 'vuex'

export default {
  components: {
    DoughnutChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: Number,
    hideTotal: Boolean,
  },
  computed: {
    ...mapState({
      status: (state) => state.enums.indicator_status,
    }),
    getLabels() {
      return [
        {
          prop: 'paid',
          label: this.status.paid,
          color: this.$indicationStatusColors.paid,
        },
        {
          prop: 'done',
          label: this.status.done,
          color: this.$indicationStatusColors.done,
        },
        {
          prop: 'kdo_sent',
          label: 'Chèques KDO envoyés',
          color: '#FFDA83',
          hidden: true,
        },
        {
          prop: 'kdo_done',
          label: 'Chèques KDO reçues',
          color: '#0DBF92',
          hidden: true,
        },
      ]
    },
  },
}
</script>
