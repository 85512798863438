<template>
  <div class="container is-fluid">
    <h1>{{ $route.meta.title }}</h1>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content data">
            <h2>Statut de la réclamation</h2>
            <ul>
              <li v-for="(status, id) in statusList" :key="id">
                <article
                  :class="
                    complaint[`status_${id.toLowerCase()}_at`]
                      ? 'valid'
                      : 'not-valid'
                  "
                  class="media"
                >
                  <figure class="media-left">
                    <component
                      :is="`icon-${id.toLowerCase()}`"
                      class="icon"
                      :class="
                        complaint[`status_${id.toLowerCase()}_at`]
                          ? 'icon-valid'
                          : 'icon-not-valid'
                      "
                    />
                  </figure>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <strong>
                          {{ statusList[id] }}
                        </strong>
                        <br />
                        Le
                        {{
                          $formatDate(
                            complaint[`status_${id.toLowerCase()}_at`],
                            'dd/MM/yyyy'
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content data">
            <h2>Coordonnées du client</h2>
            <ul>
              <li>
                <i>Nom :</i> <span>{{ complaint.lastname }}</span>
              </li>
              <li>
                <i>Prénom :</i> <span>{{ complaint.firstname }}</span>
              </li>
              <li>
                <i>CP - Ville :</i> <span>{{ complaint.postcode_city }}</span>
              </li>
              <li>
                <i>Téléphone :</i> <span>{{ complaint.phone }}</span>
              </li>
              <li>
                <i>Email :</i> <span>{{ complaint.email }}</span>
              </li>
              <li>
                <i>IDGRC / N° Client :</i> <span>{{ complaint.idgrc }}</span>
              </li>
              <li>
                <i>Date d'installation : </i
                ><span>{{
                  $formatDate(complaint.installed_at, 'dd/MM/yyyy')
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content data">
            <h2>Réclamation</h2>
            <ul>
              <li>
                <i>Type :</i>
                <span v-for="(type, id) in complaint.types" :key="id">
                  {{ typeList[type]
                  }}<span v-if="id < complaint.types.length - 1">, </span>
                </span>
              </li>
              <li>
                <i>Criticité : </i>
                <span>
                  {{ criticality[complaint.criticality] }}
                </span>
              </li>
              <li>
                <i>Description : </i>
                <div>
                  <span>
                    {{ complaint.description }}
                  </span>
                </div>
              </li>
              <li v-if="complaint.current_attachments.length">
                <i>Pièces jointes : </i>
              </li>
              <base-file-collection :files="complaint.current_attachments" />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDev">
      <b-button @click="updateStatus('NEW')">NEW</b-button>
      <b-button @click="updateStatus('DEALING')">DEALING</b-button>
      <b-button @click="updateStatus('PROCESSED')">PROCESSED</b-button>
      <b-button @click="updateStatus('DROPPED')">DROPPED</b-button>
    </div>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content data">
            <div>
              <h2>Commentaires</h2>
              <div v-if="complaint.comments">
                {{ complaint.comments }}
              </div>
              <div v-else>Aucun commentaire actuellement.</div>
            </div>
            <div v-if="complaint.status === 'DROPPED'" class="mt-5">
              <h2>Réclamation abandonnée</h2>
              <strong>{{ reasons[complaint.abandon_reason] }}</strong>
              <div>
                {{ complaint.abandon_details }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    isDev: process.env.NODE_ENV === 'development',
  }),
  computed: {
    complaint() {
      return this.$route.meta.model
    },
    ...mapState({
      criticality: (state) => state.enums.complaint.criticality,
      statusList: (state) => state.enums.complaint.status,
      typeList: (state) => state.enums.complaint.type,
      reasons: (state) => state.enums.complaint.abandon,
    }),
  },
  methods: {
    async updateStatus(status) {
      await this.$axios.patch(`/api/complaints/status/${this.complaint.id}`, {
        status: status,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
}

.data {
  .icon {
    height: 50px;
    width: 50px;
  }

  .icon-valid {
    color: #006a53;
  }

  .icon-not-valid {
    color: #4d4f5c;
  }

  .valid {
    color: black;

    strong {
      color: black;
    }
  }

  .not-valid {
    color: #4d4f5c;

    strong {
      color: #4d4f5c;
    }
  }

  h2 {
    margin-bottom: 1.25rem;
    font-weight: 600;
    font-size: 1.2rem;
  }

  li {
    margin: 0.5rem 0;

    span {
      font-weight: 600;
    }
  }
}
</style>
