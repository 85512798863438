import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import axios from '@/plugins/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    enums: {},
    complaintsImportDate: null,
  },
  mutations: {
    setEnums(state, enums) {
      state.enums = enums
    },
    setComplaintsImportDate(state, complaintsImportDate) {
      state.complaintsImportDate = complaintsImportDate
    },
  },
  actions: {
    loadEnums: async ({ commit }) => {
      let { data } = await axios.get('/api/enums')

      if (data) {
        commit('setEnums', data)
      }
    },
    loadComplaintsImportDate: async ({ commit }) => {
      let { data } = await axios.get('/api/complaint-import-updates')

      if (data) {
        commit('setComplaintsImportDate', data)
      }
    },
  },
  modules: {
    auth,
  },
})
