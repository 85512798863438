<template>
  <form @submit.prevent="onImport">
    <div class="modal-card">
      <section class="modal-card-body">
        <button type="button" class="delete" @click="$emit('close')" />

        <h4 class="header-text-message">
          <slot name="title"></slot>
        </h4>

        <div class="text-center">
          <slot name="hint"></slot>
        </div>

        <div class="form-body">
          <b-field class="file">
            <b-upload v-model="file" required>
              <a class="button">
                <b-icon icon="upload"></b-icon>
                <span>Parcourir</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-field>
          <a v-if="templateUrl" :href="templateUrl" class="link"
            >Télécharger un gabarit</a
          >

          <div class="mt-5">
            <slot></slot>
          </div>
        </div>

        <b-button class="block" native-type="submit" :loading="pending">
          Envoyer
        </b-button>

        <b-message
          auto-close
          :active.sync="isMessageActive"
          type="is-warning"
          :duration="10000"
        >
          <ul>
            <li v-for="(error, i) in errors" :key="i">
              {{ error[0] }}
            </li>
          </ul>
        </b-message>
      </section>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    templateUrl: String,
    process: Function,
  },
  data: () => ({
    file: null,
    pending: false,
    errors: [],
    isMessageActive: false,
  }),
  methods: {
    async onImport() {
      this.pending = true

      try {
        let form = new FormData()
        form.append('file', this.file)

        await this.process(form)

        this.$buefy.toast.open({
          message:
            'Votre fichier est importé de manière asynchrone, vous pouvez fermer cette fenêtre.',
          type: 'is-success',
          position: 'is-bottom',
          duration: 3000,
        })
      } catch (e) {
        if (e.response && e.response.status === 422) {
          if (e.response.data.errors) {
            this.errors = e.response.data.errors
          } else {
            this.errors = [[e.response.data.message]]
          }
          this.isMessageActive = true
        }
      } finally {
        this.file = null
        this.pending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@include tablet {
  .modal-card-body {
    padding: 4rem;
  }
}

.modal-card-body {
  .delete {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.form-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
}
</style>
