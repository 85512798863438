<template>
  <div class="container">
    <h1>{{ $route.meta.title }}</h1>
    <complaint-form></complaint-form>
  </div>
</template>

<script>
import ComplaintForm from './Form'

export default {
  components: { ComplaintForm },
}
</script>
