<template>
  <div>
    <!-- <b-button
      @click="onDelete"
      :title="softDelete ? 'Abandonner' : 'Supprimer'"
      :icon-left="softDelete ? 'archive-outline' : 'trash-can-outline'"
      :loading="loading"
    ></b-button> -->
    <b-button
      @click="isComponentModalActive = true"
      :title="softDelete ? 'Abandonner' : 'Supprimer'"
      :icon-left="softDelete ? 'archive-outline' : 'trash-can-outline'"
      :loading="loading"
    ></b-button>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
    >
      <template #default="props">
        <div class="modal-card">
          <div class="modal-card-head">Sélectionnez un motif</div>
          <div class="modal-card-body">
            <form @submit.prevent="submit">
              <b-field v-for="(label, name) in reasons" :key="name">
                <b-radio v-model="form.abandon_reason" :native-value="name">
                  {{ label }}
                </b-radio>
              </b-field>
              <transition name="fade">
                <b-field label="Détails" v-if="form.abandon_reason === 'OTHER'">
                  <b-input v-model="form.abandon_details"></b-input>
                </b-field>
              </transition>
              <b-button native-type="submit" :loading="loading">
                Abandonner
              </b-button>
              <b-button native-type="button" @click="props.close">
                Annuler
              </b-button>
            </form>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    id: [String, Number],
    confirmTitle: String,
    confirmMessage: String,
    successMessage: String,
    softDelete: Boolean,
  },
  inject: ['model'],
  data: () => ({
    loading: false,
    isComponentModalActive: false,
    form: {
      abandon_reason: '',
      abandon_details: '',
    },
  }),
  computed: {
    ...mapState({
      reasons: (state) => state.enums.complaint.abandon,
    }),
  },
  methods: {
    async submit() {
      this.loading = true

      try {
        await this.$axios.post(
          `/api/${this.model}/${this.id}/archive`,
          this.form
        )
      } finally {
        this.loading = false
      }

      this.$buefy.toast.open({
        message: this.successMessage,
        type: 'is-success',
        position: 'is-bottom',
        duration: 3000,
      })

      this.isComponentModalActive = false
      this.$emit('deleted')
    },
  },
}
</script>

<style lang="css" scoped>
.modal-card-body {
  text-align: left;
}
</style>
