import LeadChart from '@/components/charts/LeadChart.vue'
import RateChart from '@/components/charts/RateChart.vue'
import sum from 'lodash/sum'
import { mapState } from 'vuex'

export default {
  components: {
    LeadChart,
    RateChart,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    leadData() {
      if (this.chartData.counters) {
        return Object.values(this.chartData.counters)
      }
      return []
    },
    rateData() {
      return [
        'paid_amount',
        'done_amount',
        'kdo_sent_amount',
        'kdo_done_amount',
      ].map((status) => (this.chartData.rates || {})[status] || 0)
    },
    leadTotal() {
      return this.chartData.total
    },
    rateTotal() {
      return this.chartData.total_amount
    },
    conversionRate() {
      if (this.chartData.counters) {
        return (
          this.chartData.counters.done /
          sum(
            ['sent', 'paid', 'done'].map(
              (status) => this.chartData.counters[status]
            )
          )
        )
      }
      return 0
    },
  },
}
