<template>
  <b-button
    title="Se connecter en tant que cet utilisateur"
    @click="onImpersonate"
    icon-left="lock-outline"
  >
  </b-button>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async onImpersonate() {
      await this.$axios.post(`/api/users/${this.user.id}/impersonate`)

      location.href = '/'
    },
  },
}
</script>
