<template>
  <div class="container is-fluid">
    <welcome-message :update-message="!user.is_user" />
    <admin-dashboard :chart-data="chartData"></admin-dashboard>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import qs from 'qs'
import AdminDashboard from '@/components/dashboards/AdminDashboard.vue'
import WelcomeMessage from '@/components/WelcomeMessage.vue'

export default {
  components: { AdminDashboard, WelcomeMessage },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  data: () => ({
    chartData: {},
  }),
  async created() {
    /**
     * Le résultat est également groupé par domaine
     */
    let filter = {}

    if (!this.user.is_admin) {
      filter['regional_service_slug'] = this.user.regional_service_slug
    } else if (this.user.is_manager) {
      filter['manager_id'] = this.id
    }

    let { data } = await this.$axios.get(
      `/api/statistics?${qs.stringify({
        filter,
      })}`
    )

    this.chartData = data || {}
  },
}
</script>
