<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import {
  Chart,
  DoughnutController,
  ArcElement,
  Legend,
  Tooltip,
  Title,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(
  DoughnutController,
  ArcElement,
  Legend,
  Tooltip,
  Title,
  ChartDataLabels
)

export default {
  props: {
    type: {
      type: String,
      require: true,
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    chartData(val) {
      this.chart.data = val
      this.chart.update()
    },
    options(val) {
      this.chart.options = val
      this.chart.update()
    },
  },
  mounted() {
    const ctx = this.$refs.chart.getContext('2d')
    this.chart = new Chart(ctx, {
      type: this.type,
      data: this.chartData,
      plugins: [ChartDataLabels],
      options: this.options,
    })
  },
}
</script>
